import { Link } from "gatsby"
import React from "react"
import { Helpers } from "../../services/Helpers"
import { Activity } from "../../models/NationalPark"
import Colors from "../../constants/Colors"
import { FiGrid } from "react-icons/fi"
import Variables from "../../constants/Variables"
import { AiFillEuroCircle } from "react-icons/ai"
import Routes from "../../constants/Routes"

interface Props {
  activity: Activity
  parkname: string
}

export default function ActivityCard({ activity, parkname }: Props) {
  const link = `/${Helpers.sanitizeUrl(parkname)}/${
    Routes.ACTIVITY_ROUTE
  }${Helpers.sanitizeUrl(activity.activity_title[0].text)}/`

  return (
    <div className="max-w-sm lg:max-w-md rounded overflow-hidden shadow-lg mb-6 mr-0 md:mr-4">
      <Link to={link} title={activity.activity_title[0].text}>
        <div
          style={{
            background: `url(${activity.activity_image.url})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            height: 400,
          }}
        ></div>
      </Link>
      <div className="px-6 py-4 border-b border-lightest h-28">
        <Link to={link}>
          <h4 className="mt-0 mb-1">{activity.activity_title[0].text}</h4>
          {activity.activity_price !== null &&
            activity.activity_price !== undefined &&
            activity.activity_price.length > 0 && (
              <>
                <AiFillEuroCircle
                  size={15}
                  className="inline-block"
                  color={Colors.themeColor}
                  style={{ marginBottom: 0, marginRight: 2 }}
                />
                <span style={styles} className="ml-1">
                  {activity.activity_price[0].text}
                </span>
                <span style={character} className="ml-2">
                  •
                </span>
              </>
            )}
          {activity.activity_type !== null &&
            activity.activity_type !== undefined && (
              <>
                <FiGrid
                  size={15}
                  className="inline-block"
                  color={Colors.themeColor}
                  style={{ marginBottom: 0, marginRight: 2 }}
                />

                <span style={styles} className="ml-1">
                  {activity.activity_type}
                </span>
              </>
            )}
          <div className="mt-0 mb-2"></div>
        </Link>
      </div>
    </div>
  )
}

const styles = {
  color: Colors.grey,
  fontSize: 12,
} as React.CSSProperties

const character = {
  color: Colors.themeColor,
  fontSize: 12,
  fontFamily: Variables.fontFamilyHeadline,
  marginRight: 10,
} as React.CSSProperties
